import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { merge } from '../../common/SRUtils'
import { VmsIcons, VmsIconSizes } from '../../common/VmsIcons'
import { NavigationCellStyles } from './Styles'

const MANAGER2_PREFIX = '/manager2'
const VG_PORTAL_PREFIX = '/group'

class NavigationCell extends Component {
  constructor(props) {
    super(props)
    this.beforeunloadHandler = this.beforeunloadHandler.bind(this)
  }

  beforeunloadHandler = e => {
    const { state } = this.props
    if (state && state.builderIsDirty) {
      e.preventDefault()
      // eslint-disable-next-line no-param-reassign
      e.returnValue = ''
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeunloadHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunloadHandler)
  }

  render() {
    const { icon, activeIcon, isActive, text, link, isNew, isRouterMode, styles, isBeta } = this.props
    const mode = isRouterMode && (link.startsWith(MANAGER2_PREFIX) || link.startsWith(VG_PORTAL_PREFIX))
    const body = (
      <div style={merge(NavigationCellStyles.body, isActive ? NavigationCellStyles.active : {}, styles)}>
        <div style={merge(VmsIconSizes.small, NavigationCellStyles.icon)}>{isActive ? activeIcon : icon}</div>
        <div style={NavigationCellStyles.name}>{text}</div>
        {isNew ? <div style={NavigationCellStyles.newBadge}>NEW</div> : ''}
        {isBeta ? <div style={NavigationCellStyles.betaBadge}>BETA</div> : ''}
      </div>
    )
    return mode ? (
      <Link to={link} style={NavigationCellStyles.link}>
        {body}
      </Link>
    ) : (
      <a href={link} style={NavigationCellStyles.link}>
        {body}
      </a>
    )
  }
}

const PortalSwitchCell = Radium(({ icon, activeIcon, isActive, text, link }) => {
  const body = (
    <div
      style={merge(NavigationCellStyles.body, isActive ? NavigationCellStyles.active : {}, NavigationCellStyles.portalSwitchIconContainer)}
    >
      <div style={merge(VmsIconSizes.small, NavigationCellStyles.portalSwitchIcon)}>{isActive ? activeIcon : icon}</div>
      <div style={NavigationCellStyles.portalSwitchName}>{text}</div>
    </div>
  )
  return (
    <a href={link} style={NavigationCellStyles.link}>
      {body}
    </a>
  )
})

export class Navigation extends Component {
  constructor(props) {
    super(props)
    this.getNavigationCell = this.getNavigationCell.bind(this)
    this.getPortalSwitchCell = this.getPortalSwitchCell.bind(this)
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseMoveDebounced = _.debounce(this.onMouseMove, 1)
    this.state = { hover: false }
    this.scrollRef = null
  }

  onMouseOver() {
    this.setState({ hover: true })
  }

  onMouseOut() {
    this.setState({ hover: false })
  }

  onMouseMove(ev) {
    if (this.state.hover) {
      const domNode = this.scrollRef
      const boundingRect = domNode.getBoundingClientRect()
      const hoverAreaPercentage = 0.15
      const scrollRegionHeight = Math.floor(hoverAreaPercentage * boundingRect.height)
      const scrollRegionCutoffTop = boundingRect.top + scrollRegionHeight
      const scrollRegionCutoffBottom = boundingRect.top + boundingRect.height - scrollRegionHeight
      const existing = $(domNode).scrollTop()
      if (ev.clientY > scrollRegionCutoffBottom) {
        $(domNode).scrollTop(existing + 10)
      } else if (ev.clientY < scrollRegionCutoffTop && existing > 0) {
        $(domNode).scrollTop(existing - 10)
      }
    }
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.onMouseMoveDebounced, false)
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMoveDebounced, false)
  }

  getNavigationCell(enabled, icon, activeIcon, isActive, text, link, isNew, isRouterMode, state, styles, isBeta) {
    if (enabled) {
      const useRouterMode = isRouterMode === true || (isRouterMode === undefined && this.props.isRouterMode)
      return <NavigationCell {...{ icon, activeIcon, isActive, text, link, isNew, isRouterMode: useRouterMode, state, styles, isBeta }} />
    }
    return ''
  }

  getPortalSwitchCell(enabled, icon, activeIcon, isActive, text, link) {
    if (enabled) {
      return <PortalSwitchCell {...{ icon, activeIcon, isActive, text, link }} />
    }
    return ''
  }

  getInsightsCell(enabled, link, isActive, state) {
    if (!enabled) {
      return ''
    }

    const { newInsightCount } = state.insights
    const navigationCell = (
      <NavigationCell
        {...{
          icon: VmsIcons.InsightsLine,
          activeIcon: VmsIcons.Insights,
          text: 'Revenue Management',
          isNew: false,
          isRouterMode: false,
          styles: { marginBottom: '16px' },
          isActive,
          state,
          link,
        }}
      />
    )

    if (newInsightCount) {
      const insightCountDisplayLimit = 99
      return (
        <div style={{ position: 'relative' }}>
          <div style={NavigationCellStyles.newInsightBadge}>
            {newInsightCount <= insightCountDisplayLimit ? newInsightCount : `${insightCountDisplayLimit}+`}
          </div>
          {navigationCell}
        </div>
      )
    }
    return navigationCell
  }

  getSettingsRedirect(venueUrlKey, venueSettings, permissions, user_id) {
    const base = `/manager/${venueUrlKey}`
    switch (true) {
      case permissions.redirectToGeneralSettings:
        return `${MANAGER2_PREFIX}/${venueUrlKey}/settings/venue`
      case permissions.redirectToTagsList:
        return `${base}/manage/tags`
      case permissions.redirectToShifts:
        return `${base}/manage/capacity/schedule`
      case permissions.redirectToDailyProgram:
        return `${base}/manage/program`
      case permissions.redirectToReservationWidget:
        return venueSettings && venueSettings.force_redirects_on_legacy_widget && venueSettings.new_res_widget_enabled
          ? `${MANAGER2_PREFIX}/${venueUrlKey}/settings/widgets/reservation`
          : `${base}/settings/widgets/`
      case permissions.redirectToFloorplan:
        return `${base}/manage/capacity/rooms/edit`
      case permissions.redirectToUserList:
        return `${base}/access/user/list`
      case permissions.redirectToUserAccountsCRM:
        return `${MANAGER2_PREFIX}/${venueUrlKey}/settings/user_accounts/`
      default:
        if (permissions.isManagerUser) {
          return `/manager/${venueUrlKey}/manage/capacity/schedule`
        }
        return `/manager/${venueUrlKey}/account/${user_id}`
    }
  }
}

export default Navigation
