import Radium from 'radium'
import React from 'react'
import { connect } from 'react-redux'
import GlobalNotificationBanner from 'mgr/layout/GlobalNotificationBanner'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'

const StatusMessage = ({ actions, statusMessageState }) => (
  <GlobalNotificationBanner {...statusMessageState} handleClose={actions.hideMessage} />
)

const mapStateToProps = state => ({
  statusMessageState: state.statusMessageState,
})

const mapDispatchToProps = dispatch => ({
  actions: {
    hideMessage: () => {
      dispatch(GlobalActions.hideMessage())
    },
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Radium(StatusMessage))
